"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncIterator = void 0;
var Season_1 = require("./Seasons/Season");
var Chart_1 = require("./Charts/Chart");
var Page_1 = require("./Page");
var EventDeserializer_1 = require("./Events/EventDeserializer");
var StatusChange_1 = require("./Events/StatusChange");
var Workspace_1 = require("./Workspaces/Workspace");
var User_1 = require("./Users/User");
var EventLogItem_1 = require("./EventLog/EventLogItem");
var AsyncIterator = /** @class */ (function () {
    function AsyncIterator(url, client, objType, params) {
        if (params === void 0) { params = {}; }
        this.url = url;
        this.client = client;
        this.objType = objType;
        this.params = params;
        this.items = [];
        this.pages = [];
        this.index = 0;
        this.nextPageMustBeFetched = true;
    }
    AsyncIterator.prototype.charts = function (json) {
        var _this_1 = this;
        var charts = [];
        json.items.forEach(function (chartData) {
            var chart = new Chart_1.Chart(chartData);
            // @ts-ignore
            _this_1.items.push(chart);
            charts.push(chart);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(charts, json.next_page_starts_after, json.previous_page_ends_before));
    };
    AsyncIterator.prototype.events = function (data) {
        var _this_1 = this;
        var events = [];
        data.items.forEach(function (eventData) {
            var event = new EventDeserializer_1.EventDeserializer().fromJson(eventData);
            // @ts-ignore
            _this_1.items.push(event);
            events.push(event);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(events, data.next_page_starts_after, data.previous_page_ends_before));
    };
    AsyncIterator.prototype.seasons = function (data) {
        var _this_1 = this;
        var seasons = [];
        data.items.forEach(function (seasonData) {
            var season = new Season_1.Season(seasonData);
            // @ts-ignore
            _this_1.items.push(season);
            seasons.push(season);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(seasons, data.next_page_starts_after, data.previous_page_ends_before));
    };
    AsyncIterator.prototype.statusChanges = function (data) {
        var _this_1 = this;
        var statusChanges = [];
        data.items.forEach(function (statusData) {
            var statusChange = new StatusChange_1.StatusChange(statusData);
            // @ts-ignore
            _this_1.items.push(statusChange);
            statusChanges.push(statusChange);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(statusChanges, data.next_page_starts_after, data.previous_page_ends_before));
    };
    AsyncIterator.prototype.workspaces = function (data) {
        var _this_1 = this;
        var workspaces = [];
        data.items.forEach(function (json) {
            var workspace = new Workspace_1.Workspace(json);
            // @ts-ignore
            _this_1.items.push(workspace);
            workspaces.push(workspace);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(workspaces, data.next_page_starts_after, data.previous_page_ends_before));
    };
    AsyncIterator.prototype.eventLogItems = function (data) {
        var _this_1 = this;
        var eventLogItems = [];
        data.items.forEach(function (json) {
            var eventLogItem = new EventLogItem_1.EventLogItem(json);
            // @ts-ignore
            _this_1.items.push(eventLogItem);
            eventLogItems.push(eventLogItem);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(eventLogItems, data.next_page_starts_after, data.previous_page_ends_before));
    };
    AsyncIterator.prototype.users = function (data) {
        var _this_1 = this;
        var users = [];
        data.items.forEach(function (userData) {
            var user = new User_1.User(userData);
            // @ts-ignore
            _this_1.items.push(user);
            users.push(user);
        });
        // @ts-ignore
        this.pages.push(new Page_1.Page(users, data.next_page_starts_after, data.previous_page_ends_before));
    };
    AsyncIterator.prototype.fetch = function (fetchParams) {
        var _this_1 = this;
        if (fetchParams === void 0) { fetchParams = {}; }
        return this.client.get(this.url, { params: fetchParams })
            .then(function (res) {
            if (res.data.next_page_starts_after) {
                _this_1.nextPageStartsAfter = res.data.next_page_starts_after;
                _this_1.nextPageMustBeFetched = true;
            }
            else {
                _this_1.nextPageMustBeFetched = false;
            }
            switch (_this_1.objType) {
                case 'charts':
                    _this_1.charts(res.data);
                    break;
                case 'events':
                    _this_1.events(res.data);
                    break;
                case 'seasons':
                    _this_1.seasons(res.data);
                    break;
                case 'statusChanges':
                    _this_1.statusChanges(res.data);
                    break;
                case 'users':
                    _this_1.users(res.data);
                    break;
                case 'workspaces':
                    _this_1.workspaces(res.data);
                    break;
                case 'eventLogItems':
                    _this_1.eventLogItems(res.data);
                    break;
                default:
                    throw new Error("Unknown object type '".concat(_this_1.objType, "'"));
            }
        });
    };
    AsyncIterator.prototype[Symbol.asyncIterator] = function () {
        var _this = this;
        return {
            next: function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(_this.nextPageMustBeFetched && _this.items.length === 0)) return [3 /*break*/, 2];
                                return [4 /*yield*/, _this.fetch(_this.params)];
                            case 1:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 2:
                                if (!(_this.nextPageMustBeFetched && !_this.items[_this.index])) return [3 /*break*/, 4];
                                _this.params.start_after_id = _this.nextPageStartsAfter;
                                return [4 /*yield*/, _this.fetch(_this.params)];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                if (!_this.items[_this.index]) {
                                    return [2 /*return*/, Promise.resolve({
                                            done: true
                                        })];
                                }
                                else {
                                    return [2 /*return*/, Promise.resolve({ value: _this.items[_this.index++], done: false })];
                                }
                                return [2 /*return*/];
                        }
                    });
                });
            }
        };
    };
    return AsyncIterator;
}());
exports.AsyncIterator = AsyncIterator;
